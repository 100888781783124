var real_questions = [
  {
      question: "Is the tissue fake or real?",
      type: "real",
      tissue: "lung",
      img: "/imgs/real_lung_0.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_1.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_2.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_3.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_4.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_5.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_6.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_7.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_8.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_9.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_10.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_11.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_12.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_13.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_14.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_15.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_16.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_17.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_18.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_19.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "lung",
    img: "/imgs/real_lung_20.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
    question: "Is the tissue fake or real?",
    type: "real",
    tissue: "brain",
    img: "/imgs/real_brain_0.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_1.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_2.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_3.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_4.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_5.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_6.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_7.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_8.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_9.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_10.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_11.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_12.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_13.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_14.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_15.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_16.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_17.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_18.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_19.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
},
{
  question: "Is the tissue fake or real?",
  type: "real",
  tissue: "brain",
  img: "/imgs/real_brain_20.png",
  answers: [
      {
          type: "fake",
          content: "Fake"
      },
      {
          type: "real",
          content: "Real"
      }
  ]
}
];
export default real_questions;
