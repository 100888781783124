import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

function Result(props) {
  return (
    <CSSTransition
      className="container result"
      component="div"
      transitionName="fade"
      transitionEnterTimeout={800}
      transitionLeaveTimeout={500}
      transitionAppear
      transitionAppearTimeout={500}
    >
      <div>
        You score is <strong>{props.quizResult}%</strong>!
        <p>
          You said that <strong>{props.badreal} out of {props.numSamples}</strong> real images were fake!</p>
          <p>You said that <strong>{props.badfake} out of {props.numSamples}</strong> fake images were real!</p>
       
      </div>
    </CSSTransition>
  );
}

Result.propTypes = {
  quizResult: PropTypes.string.isRequired
};

export default Result;
