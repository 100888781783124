import React, { Component} from 'react';
import './App.css';
import Layout from "./components/Layout";

class About extends Component {

  render() {
    
    return (

      <div className="App">
        <div className="App-header">
          <h2>Synthetic whole-slide imaging tile generation with gene expression profiles infused deep generative models</h2>
        </div>
        <div>
          <Layout/>
        </div>
        <div className="text-container">
        
         <p className='p-homepage'>
         <p>This work has been carried out by:</p>
         <p>Francisco Carrillo-Perez <sup>1,2</sup>, Marija Pizurica<sup>1,3</sup>, Michael Ozawa<sup>4</sup>, Hannes Vogel<sup>4</sup>,
          Robert B. West<sup>4</sup>, Christina Kong<sup>4</sup>, Luis Javier Herrera<sup>2</sup>, Jeanne Shen<sup>4</sup>, Olivier Gevaert<sup>1,5</sup></p>
          <p><it><sup>1</sup> Stanford Center for Biomedical Informatics Research (BMIR), Stanford University, School of Medicine</it></p>
          <p><it><sup>2</sup> Computer Engineering, Automatics and Robotics Department, University of Granada</it></p>
          <p><it><sup>3</sup> Internet technology and Data science Lab (IDLab), Ghent University</it></p>
          <p><it><sup>4</sup> Department of Pathology, Stanford University, School of Medicine</it></p>
          <p><it><sup>5</sup> Department of Biomedical Data Science, Stanford University, School of Medicine</it></p>
          
          <p>We would like to thank Matt Van den Rijn for his collaboration evaluating the synthetic images. 
            FCP was supported by the Spanish Ministry of Sciences, Innovation and Universities under Projects 
            RTI-2018-101674-B-I00 and PID2021-128317OB-I00, the project from Junta de Andalucia P20-00163 and a 
            Predoctoral scholarship from the Fulbright Spanish Commission. Research reported here was further supported by the 
            National Cancer Institute (NCI) under award: R01 CA260271. The content is solely the responsibility of the authors 
            and does not necessarily represent the official views of the National Institutes of Health. 
            MP was supported by a fellowship from the Belgian American Educational Foundation.</p>
          </p>
          </div>
      </div>
    );
  }
}

export default About;
