var fake_questions = [
    {
        question: "Is the tissue fake or real?",
        type: "fake",
        tissue: "lung",
        img: "/imgs/vaegan_lung_0.png",
        answers: [
            {
                type: "fake",
                content: "Fake"
            },
            {
                type: "real",
                content: "Real"
            }
        ]
    },
    {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_1.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_2.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_3.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_4.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_5.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_6.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_7.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_8.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_9.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_10.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_11.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_12.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_13.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_14.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_15.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_16.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_17.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_18.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_19.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "lung",
      img: "/imgs/vaegan_lung_20.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
      question: "Is the tissue fake or real?",
      type: "fake",
      tissue: "brain",
      img: "/imgs/vaegan_brain_0.png",
      answers: [
          {
              type: "fake",
              content: "Fake"
          },
          {
              type: "real",
              content: "Real"
          }
      ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_1.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_2.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_3.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_4.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_5.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_6.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_7.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_8.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_9.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_10.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_11.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_12.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_13.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_14.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_15.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_16.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_17.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_18.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_19.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  },
  {
    question: "Is the tissue fake or real?",
    type: "fake",
    tissue: "brain",
    img: "/imgs/vaegan_brain_20.png",
    answers: [
        {
            type: "fake",
            content: "Fake"
        },
        {
            type: "real",
            content: "Real"
        }
    ]
  }
  ];
  export default fake_questions;
  