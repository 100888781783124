import React, { Component} from 'react';
import './App.css';
import Layout from "./components/Layout";

class Homepage extends Component {

  render() {
    
    return (

      <div className="App">
        <div className="App-header">
          <h2>Synthetic whole-slide imaging tile generation with gene expression profiles infused deep generative models</h2>
        </div>
        <div>
          <Layout/>
        </div>
        <div className="text-container">
        
         <p className='p-homepage'>
         <p><h4><u>Abstract</u></h4></p>
         TThe acquisition of multi-modal biological data for the same sample, such as RNA sequencing and whole-slide imaging (WSI), 
         has increased in recent years, enabling studying human biology from multiple angles. However, despite these emerging multi-modal efforts, 
         for the majority of studies, only one modality is typically available, mostly due to financial or logistical constraints. 
         Given these difficulties, cross-modal data imputation and cross-modal synthetic data generation are appealing as solutions 
         for the multi-modal data scarcity problem. Currently, most studies focus on generating a single modality (e.g., WSI), without leveraging the 
         information provided by additional data modalities (e.g., gene expression profiles). In this work, we propose an approach to generate whole-slide image (WSI) tiles by using deep generative models infused with matched gene expression profiles. 
         First, we train a variational autoencoder (VAE) that learns a latent, lower-dimensional representation of multi-tissue gene expression profiles. 
         Then, we use this representation to infuse generative adversarial networks (GANs) that generate lung and brain cortex tissue tiles, resulting in a new model that we call RNA-GAN. 
         Tiles generated by RNA-GAN were preferred by expert pathologists compared with tiles generated using traditional GANs, and in addition, RNA-GAN needs fewer training epochs to generate high-quality tiles. 
         Finally, RNA-GAN was able to generalize to gene expression profiles outside of the training set, showing imputation capabilities</p>
        </div>
        <div className='image-container'>
          <img src="rna-gan.png" alt="Architecture" width="800" height="300"/>
        </div>

        <div className="links-container">
          <a className='a-link' href='https://www.cell.com/cell-reports-methods/fulltext/S2667-2375(23)00171-6' target="_blank" rel="noreferrer">Manuscript in Cell Reports Methods</a>
          <a className='a-link' href='https://github.com/gevaertlab/RNA-GAN' target="_blank" rel="noreferrer">Code</a>
        </div>
      </div>
    );
  }
}

export default Homepage;
