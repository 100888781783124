import React, { Component } from 'react';
import fakequestions from './api/fakequestions';
import Layout from "./components/Layout";

class ImageGallery extends Component{
    constructor(props) {
        super(props);
        this.numSamples = 42;
        this.quizQuestions = fakequestions.slice(0,this.numSamples);
      }
    
    renderImage(key) {
        return(
            
            <img src={key.img} alt="tile" width="200" height="200"/>
            
        );
    }
    render() {
        return (
          <div className="App">
            <div className="App-header">
              <h2>RNA-GAN synthetic tissues</h2>
            </div>
            <div>
            <Layout/>
            </div>
            {this.quizQuestions.map(this.renderImage)}
            </div>
        );
      }
}

export default ImageGallery;