import React, { Component } from 'react';
import fakequestions from './api/fakequestions';
import realquestions from './api/realquestions';
import Quiz from './components/Quiz';
import Result from './components/Result';
import Layout from './components/Layout';
//import logo from './svg/logo.svg';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      counter: 0,
      image: '',
      questionId: 1,
      question: '',
      answerOptions: [],
      answer: '',
      answersCount: 0,
      result: '',
      real: '',
      badfake: 0,
      badreal: 0
    };
    this.numSamples = 10;
    this.quizQuestions = this.shuffleArray([...this.shuffleArray(fakequestions).slice(0,this.numSamples),...this.shuffleArray(realquestions).slice(0,this.numSamples)]);
    this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
  }

  componentDidMount() {
    // get questions fake and questions real and mix
    console.log(this.quizQuestions[0].img)
    this.setState({
      question: this.quizQuestions[0].question,
      answerOptions: this.quizQuestions[0].answers,
      image: this.quizQuestions[0].img,
      real: this.quizQuestions[0].type
    });
  }

  shuffleArray(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  handleAnswerSelected(event) {
    this.setUserAnswer(event.currentTarget.value);

    if (this.state.questionId < this.quizQuestions.length) {
      setTimeout(() => this.setNextQuestion(), 300);
    } else {
      setTimeout(() => this.setResults(this.getResults()), 300);
    }
  }

  setUserAnswer(answer) {
    var score;
    if(answer === this.state.real){
      score = 1;
    }
    else{
      score = 0;
      if(this.state.real === 'real'){
        this.setState({
          badreal: this.state.badreal + 1
        })
      }
      else{
        this.setState({
         badfake: this.state.badfake + 1
        })
      }
    }
    this.setState({
      answersCount: this.state.answersCount + score
   })
  }

  setNextQuestion() {
    const counter = this.state.counter + 1;
    const questionId = this.state.questionId + 1;

    this.setState({
      counter: counter,
      questionId: questionId,
      question: this.quizQuestions[counter].question,
      answerOptions: this.quizQuestions[counter].answers,
      image: this.quizQuestions[counter].img,
      answer: '',
      real: this.quizQuestions[counter].type,
    });
  }

  getResults() {
    var scoreFinal = (this.state.answersCount * 1.0 / (this.numSamples*2)) * 100.0;
    return scoreFinal;
  }

  setResults(result) {
    console.log(result);
    this.setState({ result: String(result) });
  }

  renderQuiz() {
    return (
      <Quiz
        answer={this.state.answer}
        answerOptions={this.state.answerOptions}
        questionId={this.state.questionId}
        question={this.state.question}
        questionTotal={this.quizQuestions.length}
        onAnswerSelected={this.handleAnswerSelected}
        image={this.state.image}
      />
    );
  }

  renderResult() {
    return <Result quizResult={this.state.result} badfake={this.state.badfake} badreal={this.state.badreal} numSamples={this.numSamples} />;
  }

  render() {
    return (
      <div className="App">
        <div className="App-header">
          <h2>RNA-GAN synthetic tissue quiz!</h2>
        </div>
        <div>
        <Layout/>
        </div>
        {this.state.result ? this.renderResult() : this.renderQuiz()}
      </div>
    );
  }
}

export default App;
