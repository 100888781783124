import { Outlet, Link } from "react-router-dom";
import './layout.css'

const Layout = () => {
  return (
    <>
      <nav>
        <ul className="nav-ul">
          <li className="nav-li">
            <Link to="/">Home</Link>
          </li>
          <li className="nav-li">
            <Link to="/about">About</Link>
          </li>
          <li className="nav-li">
            <Link to="/quiz">Quiz</Link>
          </li>
          <li className="nav-li">
            <Link to="/images">Synthetic images</Link>
          </li>
        </ul>
      </nav>

      <Outlet />
    </>
  )
};

export default Layout;
