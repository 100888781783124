import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Routes, Route} from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Homepage from './homepage';
import ImageGallery from './ImageGallery';
import About from './about';
export default function NavigationBar() {
   return (
      <BrowserRouter>
      <main>
          <Routes>
              <Route path="/" element={<Homepage/>} />
              <Route path="/about" element={<About/>} />
              <Route path="/quiz" element={<App/>} />
              <Route path="/images" element={<ImageGallery/>} />
          </Routes>
      </main>
  </BrowserRouter>
   );
 }
 
 const root = ReactDOM.createRoot(document.getElementById('root'));
 root.render(<NavigationBar />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
